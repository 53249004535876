import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import Button from "../../../components/Button";
import { InfoLightCircleIcon, MicromorgiIcon } from "../../../assets/icons/icons";
import Tippy from "@tippyjs/react";
import { ChooseSupportModal } from "../ChooseSupportModal/ChooseSupportModal";
import customAxios from "../../../utilities/axios";
const useStyles = createUseStyles((theme) => ({
  root: {
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 10px 0px",
    borderRadius: "14px",
  },
  achievementsTitle: {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "28px",
    margin: "16px 0 16px 0",
  },
  slider: {
    margin: "0 auto 16px",

    "& *": {
      minWidth: 0,
      minHeight: 0,
    },

    "& .slick-list": {
      maxWidth: "calc(100vw - 32px)",
      [theme.sUp]: {
        maxWidth: "430px",
      },
      [theme.xlUp]: {
        maxWidth: "480px"
      },
    },
  },
  rookieMembership: {
    position: "relative",
    display: "flex !important",
    flexDirection: "column",
    gap: theme.spacing,
    padding: theme.spacing * 2,
  },
  imageContent: {
    width: "100%",
    paddingBottom: theme.spacing * 2.5,
    height: "100px",
    borderRadius: 12,
    background: "linear-gradient(135deg, #808080, #3d3d3d)",
    "& img": {
      width: "100%",
      borderRadius: 12,
      objectFit: "cover",
      height: "100px",
    },
  },
  infoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: '12px',
  },
  tooltipBox: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing * 1.5,
  },
  infoBox: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing / 2,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#8649D1",
  },
  customDotsContainer: {
    margin: "0 auto",
    display: "flex",
    position: "relative",
    gap: 4,
    justifyContent: "center",
    width: "100%",
    maxWidth: 310,
    top: "-20px",
    zIndex: 2,
  },
  customDot: {
    height: "7px",
    width: "7px",
    background: "#D9D9D9",
    opacity: "0.7",
    borderRadius: "50%",
    "&.active": {
      opacity: "1",
      background: "#00063D"
    },
  },
  button: {
    width: "100%",
    maxWidth: "100%",
    display: 'flex',
    "& span": {
      display: "flex",
      width: '100%',
      alignItems: "center",
      justifyContent: "space-between",
    },
    padding: `${theme.spacing / 1.5}px ${theme.spacing * 3}px`,
  },
  buttonContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: `${theme.spacing / 4}px`,
    "& svg": {
      width: '17px',
      height: '17px'
    }
  },
  popoverContent: {
    maxWidth: 315,
    padding: theme.spacing,
    textAlign: "center",
  },
  tooltipTrigger: {
    "& svg": {
      cursor: "pointer",
      "& path": {
        opacity: 1
      }
    }
  },
  tippy: {
    padding: '4px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    width: 315,
    maxHeight: 66,
    borderRadius: 4,
    borderRadius: '14px',
    zIndex: "3"
  },
}));
export const RookieMembershipSection = ({ rookieProfile }) => {
  const classes = useStyles();
  const [contentIndex, setContentIndex] = useState(0);
  const [selectedMembership, setSelectedMembership] = useState()
  const [openChooseSupportModal, setOpenChooseSupportModal] = useState(false)
  const [t] = useTranslation(getAvailableNamespaces(), {
    react: { useSuspense: false },
  });

  const [packagesList, setPackagesList] = useState()

  
  useEffect(() => {
    const fetch = async () => {
        if (rookieProfile.id) {
            const { data } = await customAxios.get(`/subscriptions/rookies/${rookieProfile?.id}/packages`)
            setPackagesList(data)
        }
    }
    fetch()
}, [rookieProfile?.id])

  const settings = {
    infinite: false,
    speed: 200,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    adaptiveHeight: true,
    afterChange: (current) => {
      setContentIndex(current);
    },
  };

  const handleChooseSupportModal = () => {
    setOpenChooseSupportModal(!openChooseSupportModal)
  }

  return (
    <>
      <h4 className={classes.achievementsTitle}>
        {t('rookie_profile_page.membership.title')}
      </h4>
      <div className={classes.root}>
        <Slider {...settings} className={classes.slider}>
          {packagesList?.map((item, index) => (
            <div key={item.id} className={classes.rookieMembership}>
              <div className={classes.imageContent}>
               {!!item?.group?.image_path_location && <img src={item.group.image_path_location} alt="membership" />}
              </div>
              <div className={classes.infoContainer}>
                <div className={classes.infoBox}>
                  <div className={classes.circle} />
                  {item.surprises_count}
                </div>
                <div className={classes.tooltipBox}>
                  <div className={classes.infoBox}>
                    <div className={classes.circle} />
                    {item.group.name}
                  </div>
                  <div>
                   {!!item?.group?.messages?.length && <Tippy
                      theme="light"
                      placement={'bottom'}
                      trigger="click"
                      inlinePositioning={true}
                      interactive={true}
                      className={classes.tippy}
                      content={
                        <span>{item.group.messages[0]}</span>
                      }
                    >
                      <span className={classes.tooltipTrigger}>
                        <InfoLightCircleIcon width='10px' height='10px' />
                      </span>
                    </Tippy>}
                  </div>
                </div>
              </div>

              <Button
                data-primary
                className={classes.button}
                onClick={() => {
                  setSelectedMembership(item);
                  handleChooseSupportModal()
                }}
              >
                <div>{t('rookie_profile_page.membership.subscribe')}</div>
                <div>
                  <div className={classes.buttonContent}>
                    {item.amount}
                    <MicromorgiIcon />
                    {`$(${item.dollar_amount})`}
                  </div>
                  <div className={classes.buttonContent}>
                    {t('rookie_profile_page.membership.month')}
                  </div>
                </div>
              </Button>
            </div>
          ))}

        </Slider>

        <div className={classes.customDotsContainer}>
          {packagesList?.map((_, idx) => (
            <div
              key={idx}
              className={`${classes.customDot} ${idx === contentIndex ? "active" : ""
                }`}
            />
          ))}
        </div>
      </div>
      {openChooseSupportModal &&
        <ChooseSupportModal
          onClose={handleChooseSupportModal}
          content={selectedMembership}
          rookieProfile={rookieProfile}
        />
      }
    </>
  );
};